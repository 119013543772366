/**
  * _variables.scss
  * Contains SCSS variables used in this website's stylesheet.
  */

// Globals
$font-stack: 'Source Sans Pro', sans-serif;
$font-size: 17px;
$font-size-mobile: 15px;

$code-font-stack: 'Source Code Pro', monospace;

// Body
$body-text-color: #515151;
$body-background-color: #ffffff;
$body-top-border-color: #0A3b76;
$body-top-border-width: 5px;

// Links
$link-color: #5a81b0;

// Navigation
$nav-bottom-border-color: #c0c0c0;
$nav-bottom-border-width: 1px;

$nav-link-color: #263663;
$nav-link-padding-y: 0.7rem;
$nav-link-padding-x: 1.5rem;
$nav-link-underline-color: #2098d1;

$nav-icon-color: #ffffff;
$nav-icon-background-color: #0A3b76;

// Blog post headings and page titles
$post-title-color: #0A3b76;
$post-date-color: #9a9a9a;

// Message box
$message-color: #717171;
$message-background-color: #f9f9f9;
