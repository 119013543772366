/**
 * _landing.scss
 * Styles for the landing page
 */

.landing-title {
    color: $post-title-color;
}

.landing-subtitle {
    color: $post-title-color;
    border-bottom: 1px solid #c0c0c0;
    padding-bottom: 0.7rem;
}
