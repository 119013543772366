// Pagination
//
// Super lightweight (HTML-wise) blog pagination. `span`s are provided for when
// there are no more previous or next posts to show.

.pagination {
  font-family: $font-stack;
  overflow: hidden; // clearfix
  margin: 3rem 0;
  
  color: #ccc;
  text-align: center;

  .pagination-text, .fa {
    vertical-align: middle;
  }
}

.pagination-item {
  &:first-child {
    float: left;
  }
  &:last-child {
    float: right;
  }
}

a.pagination-item {
  &:focus,
  &:hover {
    text-decoration: none;
    background-color: #f5f5f5;
  }
}

@media (max-width: 30em) {
  .pagination {
    margin: 0 -1.5rem 1rem;

    .fa {
      font-size: 2rem;
    } 
  }

  .pagination-item {
    padding: 0 1rem;

    &:first-child, &:last-child {
      float: none;
    }
  }

  .pagination-text {
    display: none;
  }
}
