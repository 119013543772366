/**
  * _message.scss
  * Show alert messages to users. You may add it to single elements like a `<p>`,
  * or to a parent if there are multiple elements to show.
  */

.message {
  color: $message-color;
  background-color: $message-background-color;
  margin-bottom: 1rem;
  padding: 1rem;
}
