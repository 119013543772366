/**
  * _posts.scss
  * Each post is wrapped in `.post` and is used on default and post layouts. Each
  * page is wrapped in `.page` and is only used on the page layout.
  */

.page,
.post {
  margin-bottom: 4em;
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: $post-title-color;
}
.page-title,
.post-title {
  margin-top: 0;
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: $post-date-color;
}
