/**
  * _utils.scss
  * Utility classes for applying common styles to elements.
  */

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

  .text-center {
    text-align: center;
  }

  ul.padded-list {
    padding: 1rem;
  }

  li.padded-list:not(:last-child) {
    margin-bottom: 1rem;
  }

  ol.bibliography > * {
    margin-bottom: 1rem;

    @include word-wrap
  }
 
     @media screen and (min-width: 600px)  {
        .mobile-break { display: none; }
    } 
